.downloads {
	.heading {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-color: #fff transparent transparent transparent;
		}
		position: relative;
		// margin: 1rem 0;
		min-height: 100%;
		// background: url(http://placekitten.com/1920/1080);
		background-position: center;
		background-size: cover;
		box-shadow: inset 0 0 0 2000px rgba(23, 64, 78, 0.81);
		padding: 4rem 0;
		// justify-self: ;

		h1 {
			color: white;
			font-size: 2.5rem;
			text-align: center;
		}
	}

	.content {
		margin: 5rem auto;

		h2 {
			text-align: center;
			color: #444;
		}

		.download-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 2rem auto;
			.section {
				border-collapse: unset;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
				border-radius: 4px;
				position: relative;
				overflow: hidden;
				width: 300px;
				margin: 1rem auto;

				.table-heading {
					background-color: #17404e;
					padding: 1rem;
					h2 {
						color: white;
						font-size: 1.2rem;
					}
				}

				.list {
					padding: 1rem;
					min-height: 200px;
					// display: flex;
					// justify-content: center;
					// flex-direction: column;
					a {
						display: block;
						text-align: center;
						text-decoration: none;
						padding: 1rem 0;
						font-weight: 400;
						color: #27ae60;
					}
				}
			}
		}
	}
}
