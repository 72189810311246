.header_company{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #35589A;
    color: white;
    font-size: 1.3rem;
    padding: 0.5rem;
    border-radius: 5px;
}
.companyaddform{
    display: flex;
    align-items: center;
    justify-content: center;
}
.deatailscompanybuttons{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.deatailscompanybuttons div{
    display: flex;
    align-items: center;
    margin: 1vh 0;
    justify-content: space-between;
}
.deatailscompanybuttons div Button{
    margin: 0 3vw;
}   
.detailscomapny div{
    display: flex;
    align-items: center;
    justify-content:space-between;
}
@media only screen and (max-width: 700px) {
    .oncamp_side{
        display: none;
    }
    .oncamp_rest{
        width: 100%;
        margin: 0;
    }
    .detailscomapny{
        grid-template-columns: repeat(1, 1fr);

    }
    .deatailscompanybuttons div{
        display: flex;
        flex-direction: column;
    }
    .deatailscompanybuttons div div button{
        margin:2vh 0;
    }
    

}
