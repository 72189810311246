.about-us {
	.blank_nav {
		height: 80px;
		background-color: #f8f8f8;
	}

	.wrapper {
		overflow: hidden;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-color: #fff transparent transparent transparent;
		}
		position: relative;
		min-height: 100%;
		// background: url(http://placekitten.com/1920/1080);
		background-size: cover;
		background-position: center;
		box-shadow: inset 0 0 0 2000px rgba(53,88,154,0.81);
		padding: 4rem 0;

		h2 {
			color: white;
			font-size: 2.5rem;
			// text-align: center;
			margin-bottom: 2rem;
		}
	}
	.px-2 {
		align-items: center;
		justify-content: space-between;

		.content {
			color: aliceblue;
		}
		img {
			width: 500px !important;
			height: 500px !important;
			object-fit: cover;
		}
	}
	.placement-cell {
		.container {
			margin: 5rem auto;
		}
		h2 {
			margin-bottom: 1.5rem;
			text-align: center;
		}
	}
}

@media screen and (max-width: 992px) {
	.about-us {
		.placement-cell {
			.container {
				text-align: center;
			}
		}
	}
}
