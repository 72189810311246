.header_company{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #35589A;
    color: white;
    font-size: 1.3rem;
    padding: 0.5rem;
    border-radius: 5px;
}
.upcomingcompanies{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.eachupcomingcompany{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 0;
    width:100%;
    background-color: #FFFFFF;
}
.upcomingcompanyname{
    font-weight: 700;
}