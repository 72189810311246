.login__main{
    display: flex;
            width: 100%;
    flex-direction: row; 
    align-items: center;
    justify-content: center;
}
.login__left{
    width: 35%;
    background-color: #35589A;
    height:100vh;
    text-align: center;
}
.login__right{
    justify-content: center;
    width: 100%;
    display: flex;
    padding:3rem;
    flex-direction: column;
    align-items: center;
}
@media only screen and (max-width: 600px) {
    .login__left{
        width: 100%;
    }
    .login__right{
        padding:0;
        width: 100%;
        display: normal;
        height: 100vh;
    }
    .login__main{
        max-width: 100%;
        flex-direction: column;
    }
  }