.header_company{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #35589A;
    color: white;
    font-size: 1.3rem;
    padding: 0.5rem;
    border-radius: 5px;
}
.upcomingcompanies{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    overflow: auto;
    font-weight: 700;
    color:#576F72;
    height:30vh;
}
.eachupcomingcompany{
    /* display: flex; */
    align-items: center;
    /* justify-content: space-evenly; */
    padding: 2vh 0;
    width:100%;
    background-color: #FFFFFF;
}
.upcomingcompanyname{
    font-weight: 700;
    color:#3F4E4F;
}
.datea{
    font-weight: 700;
    color:#576F72;
}