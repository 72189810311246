.contact-us {
	.heading {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-color: #fff transparent transparent transparent;
		}
		position: relative;
		// margin: 1rem 0;
		min-height: 100%;
		// background: url(http://placekitten.com/1920/1080);
		background-position: center;
		background-size: cover;
		box-shadow: inset 0 0 0 2000px rgba(53,88,154, 0.81);
		padding: 4rem 0;

		h1 {
			color: white;
			font-size: 2.5rem;
			text-align: center;
		}
	}
	.content {
		display: flex;
		margin: 2rem auto;
		text-align: center;
		justify-content: space-between;
		align-items: center;
		// width: 100%;
		flex-shrink: 0 !important;
		flex-wrap: wrap;
		justify-content: space-between;

		@media (max-width: 992px) {
			justify-content: center;
		}

		div {
			display: flex;
			justify-content: center;
			align-items: center;
			// flex-basis: 25%;
			min-width: 260px;

			box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
			border-radius: 4px;

			margin: 0.5rem;
			color: #777;
			min-height: 15rem;

			flex-direction: column;

			img {
				height: 50px;
				width: 50px;
				margin-bottom: 1rem;
			}

			h2 {
				font-size: 1.5rem;
				color: #444;
				margin-bottom: 0.5rem;
			}
			p {
				margin-bottom: 0;
				a {
					text-decoration: none;
					color: #27ae60;
				}
			}

			&.address {
				// flex-basis: 40%;
				// flex-grow: 2;
				margin-left: 0;
			}

			&.call {
				h2 {
					margin-bottom: 1rem;
				}
				a {
					text-decoration: none;
					color: #777;
					margin: 0;
				}
			}

			&.email {
				// flex-basis: 40%;
				// flex-grow: 2;
				h2 {
					margin-bottom: 1rem;
				}
				// p {
				// 	margin-bottom: 0.2rem;
				// }
			}

			&.linkedin {
				cursor: pointer;
				margin-right: 0;
			}
		}

		// .address {
		// 	flex-basis: 40%;
		// 	box-shadow: 0 0 30px rgba(214, 215, 216, 30%);
		// }
		// .email,
		// .call {

		// }
	}

	.email-form {
		margin: 3rem auto;

		h2 {
			margin: 1rem 0;
			text-align: center;
			color: #444;
		}

		form {
			label {
				font-weight: 400;
			}
		}

		button {
			background-color: #27ae60;
			border: none;
		}

		p {
			margin-bottom: 1rem;
			padding: 0.5rem 1rem;
			border-radius: 4px;
			&.success {
				background-color: #a5d6a7;
			}
			&.error {
				background-color: #ffab91;
			}
		}
	}

	.map {
		margin: 4rem auto;
		iframe {
			width: 100%;
			height: 600px;
			border: 0;
			box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
			border-radius: 4px;
		}
	}
}
