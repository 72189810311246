.sjce-footer {
	padding: 2rem;
	background-color: #35589A;
	display: flex;
	// flex-direction: column;
	align-items: center;
	justify-content: center;
	position:relative;
left:0;
bottom:0;
right:0;

	@media (max-width: 992px) {
		flex-direction: column;
		img {
			margin: 1rem;
		}
	}

	.footer-content {
		.footer-links {
			display: flex;
			justify-content: center;
			margin: 1rem 0;
			.image-wrapper {
				// margin: 0 1rem;
				cursor: pointer;
				margin: 0 0.5rem;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				background-color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					width: 1.1rem;
					margin: 0;
				}
			}
		}
	}

	img {
		cursor: pointer;
		width: 100px;
		margin-right: 2rem;
	}
	h2 {
		text-align: center;
		color: white;
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 1.5rem;
	}
	h1 {
		text-align: center;
		color: white;
		font-size: 20px;
		font-weight: 500;
		text-transform: uppercase;
	}
}
