.process {
	.min-height-wrapper {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 5.4rem);
		justify-content: space-between;
		.heading {
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				border-width: 15px;
				border-style: solid;
				border-color: #fff transparent transparent transparent;
			}
			position: relative;
			// margin: 1rem 0;
			min-height: 100%;
			// background: url(http://placekitten.com/1920/1080);
			background-position: center;
			background-size: cover;
			box-shadow: inset 0 0 0 2000px rgba(23, 64, 78, 0.81);
			padding: 4rem 0;
			// justify-self: ;

			h1 {
				color: white;
				font-size: 2.5rem;
				text-align: center;
			}
		}

		.image-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 4rem auto 2rem auto;
			img {
				width: 100%;
			}
		}
	}
}
